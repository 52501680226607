<template>
  <div class="organ-management-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="form" class="demo-form-inline search searchFrom" size="small">
            <el-form-item label="资讯名称:">
              <el-input
                v-model="form.name"
                :clearable="true"
                placeholder="请输入资讯名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="状态:">
              <el-select v-model="form.region" placeholder="请选择状态">
                <el-option label="全部 " value=""></el-option>
                <el-option label="有效" value="0"></el-option>
                <el-option label="无效" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="资讯类型:">
              <el-select class="select" v-model="form.regison1" @change="changeSelect" placeholder="请选择资讯类型">
                <el-option label="全部 " value=""></el-option>
                <el-option v-for="(item, index) in infomationList" :key="index" :label="item.dictionaryName" :value="item.dictionaryCode"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始时间:">
              <el-date-picker
                v-model="form.value1"
                value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                :picker-options="pickerOptionsStart"
                type="date"
                placeholder="选择开始时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间:">
              <el-date-picker
                v-model="form.value2"
                type="date"
                value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                :picker-options="pickerOptionsEnd"
                placeholder="选择结束时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-search" type="primary" :disabled="disabledSearch" @click="searchRes(1)">查询</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-card shadow="always" class="card">
      <div>
        <el-button plain icon="el-icon-plus" type="primary" @click="addNew('', 1)">添加</el-button>
        <el-button plain icon="el-icon-delete" type="danger" @click="handleDelete">删除</el-button>
      </div>
      <div class="table-block">
        <div class="Selectnum">已选中 <span style="color:#5479FF">{{multipleSelection.length}}</span> 条信息</div>
        <el-table v-loading="loading" ref="multipleTable" @row-click="handleClickTableRow" :data="tableData" :header-cell-style="{'text-align': 'center','background': '#EEF1FC', 'color': '#999'}" :cell-style="{'text-align':'center'}" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" fixed width="50"></el-table-column>
          <!-- <el-table-column type="index" width="50" label="序号"></el-table-column> -->
          <el-table-column prop="informationTitle" label="资讯名称"> </el-table-column>
          <el-table-column prop="beginDate" label="开始时间"> </el-table-column>
          <el-table-column prop="endDate" label="结束时间"> </el-table-column>
          <el-table-column prop="createByName" width="120" label="添加人"> </el-table-column>
          <el-table-column prop="createTime" width="200" label="添加时间"> </el-table-column>
          <el-table-column prop="state" label="状态"  width="120">
            <template slot-scope="scope">
               <el-switch
                v-model="scope.row.status"
                active-color="#5479FF"
                inactive-color="#D5D5D5"
                active-value="0"
                @change="changeStatus(scope.row)"
                inactive-value="1">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="80">
            <template slot-scope="scope">
              <el-button @click="updateNew(scope.row, 2)" class="updatebutton" type="text" size="small">修改</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="1"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      background>
    </el-pagination>
    </el-card>
    
    <infomationType :info="info" v-if="showInfomationType" @closeAddinfo="closeAddinfo"></infomationType>
  </div>
</template>

<script>
import infomationType from '@/views/infomationManagement/components/infomationType'
import { postRequest, postRequestAnthor } from '@/api';
import { formatTime } from '@/utils/utils';
export default {
  data () {
    return {
      form: {
        name: '',
        regison: '',
        regison1: '',
        value1: '',
        value2: ''
      },
      tableData: [],
      loading: true,
      disabledSearch: false,
      multipleSelection: [],
      showInfomationType: false,
      pageNo:1,
      pageSize: 10,
      total: null,
      info: '',
      infomationList: [],
      pickerOptionsStart: {
                     disabledDate: time => {
                            if (this.form.value2) {
                                 return time.getTime() > new Date(this.form.value2).getTime()
                           }
                    }
             },
             pickerOptionsEnd: {
                      disabledDate: time => {
                            if (this.form.value1) {
                                    return time.getTime() < new Date(this.form.value1).getTime() - 86400000
                            }
                       }
             },
    }
  },
  components: { infomationType },
  mounted(){
    let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        informationTitle: '',
        status: '',
        informationType: '',
        beginDate: '',
        endDate: ''
      }
    this.getAllInfomation(data)
    postRequestAnthor('/dict/getDictByCategory/',{dictionaryCategoryCode: '08'}).then(res=>{
      console.log(res,'资讯类型')
      this.infomationList = res
    })
  },
  methods: {
    // 点击行选中
    handleClickTableRow(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 选中项
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val
    },
    changeSelect(val){
      console.log(val, this.form.regison1, 'xiugai')
    },
    addNew () {
      console.log(123)
      this.info = ''
      this.showInfomationType = true
    },
    searchRes(no){
      this.loading = true
      this.disabledSearch = true
      let data = {
        informationTitle: this.form.name ? this.form.name : '',
        informationType: this.form.regison1 ? this.form.regison1 : '',
        beginDate: this.form.value1 ? this.form.value1 : '',
        endDate: this.form.value2 ? this.form.value2 : '',
        status: this.form.region ? this.form.region : '',
        pageNo: no ? no : this.pageNo,
        pageSize: this.pageSize
      }
      postRequestAnthor('/information/queryinformationlist', data).then(res=>{
        this.disabledSearch = false
        console.log(res, '资讯')
        res.data.map(item => {
          if (item.status == '0') {
            item.state = '有效'
          }
          if (item.status == '1') {
            item.state = '无效'
          }
        })
        this.total = res.count
        this.loading = false
        this.tableData = res.data
      }).catch(()=>{
        this.loading = false
        this.disabledSearch = false
      })
    },
    changeStatus(val){
      let data = {
        id: val.id,
        status: val.status
      }
      postRequestAnthor('/information/updatestatus', data).then(res=>{
        console.log(res)
        this.$message({
          message: '状态修改成功',
          type: 'success'
        })
        // this.getAllInfomation()
      })
    },
    updateNew(val){
      console.log(val)
      this.info = val
      this.showInfomationType = true
    },
    closeAddinfo () {
      this.showInfomationType = false
      this.getAllInfomation()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.searchRes()
    },
    handleDelete(){
      if (this.multipleSelection.length <=0 ) {
        this.$message({
          message: '请选择删除项',
          type: 'warning'
        })
      } else {
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          let deleteArr = []
          this.multipleSelection.map(item => {
            deleteArr.push(item.id)
          })
          postRequestAnthor('/information/deletes', {ids: deleteArr}).then(res=>{
            console.log(res, '删除')
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.getAllInfomation()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },

    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNo = val
      this.searchRes()
    },
    //  全量查询
    getAllInfomation () {
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        informationTitle: '',
        status: '',
        informationType: '',
        beginDate: '',
        endDate: ''
      }
      postRequestAnthor('/information/queryinformationlist', data).then(res=>{
        console.log(res, '资讯')
        res.data.map(item => {
          if (item.status == '0') {
            item.state = '有效'
          }
          if (item.status == '1') {
            item.state = '无效'
          }
        })
        this.total = res.count
        this.tableData = res.data
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// .search > .el-form-item {
//   margin-right: 2px !important;
// }
.organ-management-page {
  background: transparent;
}
.Selectnum{
  padding:6px 30px;
  border:1px solid #BFCBF4;
  font-size: 12px;
  color:#666;
  border-radius: 5px;
  margin-bottom:10px;
}
</style>
