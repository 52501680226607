<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="60%"
    :show-close="false"
    :close-on-click-modal="false"
    @close="handleClose">
      <el-card v-loading="loading">
        <el-form ref="form" :rules="rules" :model="form" label-width="90px">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="资讯标题:" prop="informationTitle">
                <el-input v-model="form.informationTitle" placeholder="请输入资讯标题" type="text" maxlength="50" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="资讯类型:" prop="informationType">
                <el-select class="select" v-model="form.informationType" placeholder="请选择资讯类型">
                  <el-option v-for="(item, index) in infomationList" :key="index" :label="item.dictionaryName" :value="item.dictionaryCode"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="开始日期:" prop="beginDate">
                <el-date-picker type="date" :picker-options="pickerSater" @change="changeBeginDate" placeholder="请选择开始日期" v-model="form.beginDate" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="结束时间:" prop="endDate">
                <el-date-picker type="date" :picker-options="pickerEnd" @change="changeEndDate" placeholder="请选择结束日期" v-model="form.endDate" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="URL:">
                <el-input v-model="form.linkUrl" type="text" placeholder="请输入链接地址" show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="上传封面">
                <el-upload
                  :class="imageUrl ? '' : 'avatar-uploader'"
                  :action="action"
                  :show-file-list="false"
                  :before-upload="beforeAvatarUpload"
                  :on-success="handleAvatarSuccess">
                  <img v-if="imageUrl" :src="imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="上传图片">
                <el-upload
                  :class="imageUrl1 ? '' : 'avatar-uploader'"
                  :action="action"
                  :show-file-list="false"
                  :before-upload="beforeAvatarUpload"
                  :on-success="handleAvatarSuccess1">
                  <img v-if="imageUrl1" :src="imageUrl1" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="资讯文案" prop="content">
              <fuwenben ref="editor"
                v-model="form.content"
                @onClick="onClick">
              </fuwenben>
          </el-form-item>
        </el-form>
      </el-card>
    <span slot="footer" class="dialog-footer">  
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sureCommit('form')">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { postRequestAnthor } from '@/api'
import E from 'wangeditor'
import { formatTime } from '@/utils/utils'
import {imgaction} from 'src/utils/cities'
import fuwenben from '@/views/infomationManagement/components/fuwenben'
export default {
  data () {
    return {
      form: {
        informationTitle: '',
        informationType: '',
        beginDate: '',
        endDate: '',
        content: '',
        updateBy: '1',
        createBy: '2',
      },
      rules: {
        informationTitle: [
          {required: true, message: '请输入资讯标题信息', trigger: 'blur'}
        ],
        informationType: [
          {required: true, message: '请选择资讯类型', trigger: 'change'}
        ],
        beginDate: [
          {required: true, message: '请选择开始时间', trigger: 'change'}
        ],
        endDate: [
          {required: true, message: '请选择结束时间', trigger: 'change'}
        ],
        content: [
          {required: false, message: '请输入文案信息', trigger: 'change'}
        ]
      },
      editorOption: {
        placeholder: "请输入正文",
      },
      loading: false,
      catchData: '',
      action: imgaction,
      dialogVisible: true,
      imageUrl: '',
      infomationList: [],
      isShow: false,
      imageUrl1: '',
      pickerSater: {
        disabledDate: (time) => {
          return time.getTime() < new Date() - 24 * 60 * 60 * 1000;
        },
      },
      pickerEnd: {
        disabledDate: (time) => {
          return time.getTime() < new Date(this.form.beginDate);
        },
      },
    }
  },
  components: {fuwenben},
  props: ['info'],
  mounted(){
    console.log(this.info, 'info')
    if (this.info != '') {
      this.loading = true
      // this.imageUrl = this.info.coverImage
      // this.imageUrl1 = this.info.coverTypeUrl
      this.getOneInformation({id:this.info.id})
    }
    postRequestAnthor('/dict/getDictByCategory/',{dictionaryCategoryCode: '08'}).then(res=>{
      console.log(res,'资讯类型')
      this.infomationList = res
    })
  },
  methods: {
    updated () {
      const editor = new E('#word')
      editor.create()
    },
    handleAvatarSuccess(res, file) {
      console.log(res, 'res', file, 'file')
      if (res.code == 200) {
        this.$message({
          message: '图片上传成功',
          type: 'success'
        })
        this.form.coverImage = res.data
        this.imageUrl = URL.createObjectURL(file.raw);
      }
    },
    handleAvatarSuccess1(res, file) {
      console.log(res, 'res', file, 'file')
      if (res.code == 200) {
        this.$message({
          message: '图片上传成功',
          type: 'success'
        })
        this.form.coverTypeUrl = res.data
        this.imageUrl1 = URL.createObjectURL(file.raw);
      }
    },
    showFuWenBen(){
      this.isShow = true
    },
    onClick (e, editor) {
      console.log('Element clicked')
      console.log(e)
      console.log(editor)
    },
    changeDate123(val){
      console.log(123)
      this.$forceUpdate()
      console.log(val)
      this.form.beginDate = val[0]
      this.form.endDate = val[1]
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传封面图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    changeBeginDate(val){
      this.form.beginDate = formatTime(val)
      console.log(this.form.beginDate, '开始时间')
      this.form.endDate = ''
    },
    getOneInformation(data){
      postRequestAnthor('/information/queryinformationinfo',data).then(res=>{
        this.loading = false
        console.log(res, '单条信息')
        res.value1 = [res.beginDate, res.endDate]
        this.imageUrl = res.coverImage
        this.imageUrl1 = res.coverTypeUrl
        this.form = res
        this.form = {...res}
        this.form.updateBy = '1'
        this.form.createBy = '2'
      }).catch(()=>{
        this.loading = false
      })
    },
    changeEndDate(val){
      this.form.endDate = formatTime(val)
      console.log(this.form.endDate, '结束时间')
    },
    handleClose () {
      this.$emit('closeAddinfo')
    },
    sureCommit(form){
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.form.linkUrl || this.form.content) {
            if (this.info != '') {
              postRequestAnthor('/information/update', this.form).then(res=>{
                console.log(res)
                this.$message({
                  message: '修改成功',
                  type: 'success'
                })
                this.$emit('closeAddinfo')
              })
            } else {
              postRequestAnthor('/information/addsave', this.form).then(res=>{
                console.log(res, '提交结果')
                this.$message({
                  message: '新增成功',
                  type: 'success'
                })
                this.$emit('closeAddinfo')
              })
            }
          } else {
            this.$message({
              message: '请输入URL或者资讯文案',
              type: 'warning'
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>

<style lang='scss' scoped>
.select {
  width: 100% !important;
}
#word {
  width: 100%;
  height: 200px;
}
.ql-editor {
  height: 200px;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #5479FF;
    width: 147px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 147px;
    height: 178px;
    display: block;
  }
  .avatar[data-v-6df196da] {
    width: 100%;
    height: 100%;
  }
.el-range-editor {
  width: 100% !important;
}
.avatar-uploader {
  width: 147px;
  border: 1px dashed #5479FF;
  
}
.el-card {
  margin-top: -20px;
}
.dialog-footer {
  margin-top: -20px !important;
}
</style>